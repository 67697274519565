/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

body{
    font-family: "Inter", sans-serif !important;
}
*{
  // font-family: "Inter", sans-serif !important;
  font-family: "Poppins", sans-serif !important;
}

.inter-font {
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
    font-variation-settings:
      "slnt" 0;
  }

  // .inter-font-16 {
  //   font-weight: 600 !important;
  //   font-size: 16px !important;
  // }

  mat-label {
    font-weight: 500 !important;
    font-size: 14px !important;
  }
  .mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-form-field-subscript-wrapper {
    display: none !important;
  }
//   .mat-mdc-unelevated-button.mat-primary{
//     background-color: #09B9FF !important;
// }
// .bg-primary.new-button{
//   background-color: #09B9FF !important;
// }
select:not([size]) {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 10 6'%3E%3Cpath stroke='%236B7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m1 1 4 4 4-4'/%3E%3C/svg%3E");
  background-position: right .75rem center;
  background-repeat: no-repeat;
  background-size: .75em .75em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}
select:not(:-internal-list-box) {
  overflow: visible !important;
}
select {
  text-transform: none;
}
select {
  -webkit-appearance: none !important;
  appearance: none !important;
}
.bg-gray-new{
  background-color: #F1F5F9 !important;
}
.login-bg{
  background-image: url("http://43.229.227.26:81/CarpetEstimating/assets/images/login-bg-3.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
// .bg-blue-shade{
  
// }
fuse-vertical-navigation.fuse-vertical-navigation-position-left.fuse-vertical-navigation-mode-side.fuse-vertical-navigation-opened {
  background-color: #03399B !important;
}
.mat-mdc-unelevated-button.mat-primary{
  background-color: #0449c7 !important;
  }
  .bg-primary.new-button{
    background-color: #0449c7 !important;
  }